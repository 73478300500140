import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-172847214-1');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
