import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-family: Nunito Sans;
    display: flex;
    justify-content: center;
    flex-direction: column;
    zoom: 1.0;
  }

  h1 {
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    color: #001A08;
    letter-spacing: -0.03em;
  }

  h2 {
    font-family: Nunito Sans;
    font-size: 24px;
    font-weight: 700;
    color: #001A08;
    letter-spacing: -0.03em;
  }

  .hint {
    margin-top: 4px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #738C7B;
  }

  .error {
    margin-top: 4px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #FC5819;
  }
`
