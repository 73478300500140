import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import ReactGA from 'react-ga';

//import Home from './pages/Home'
// import Produto from './pages/Produto'
// import Search from './pages/Search'
// import Categoria from './pages/Categoria'
// import Login from './pages/Login'
// import CadastroUsuario from './pages/CadastroUsuario'
// import MinhaConta from './pages/MinhaConta'
// import CadastroAnuncio from './pages/CadastroAnuncio'
// import MinhasVendas from './pages/MinhasVendas'

import dataCategoria from './services/categorias.json'

const Home = lazy(() => import('./pages/Home'))
const Produto = lazy(() => import('./pages/Produto'))
const Search = lazy(() => import('./pages/Search'))
const Categoria = lazy(() => import('./pages/Categoria'))
const Login = lazy(() => import('./pages/Login'))
const CadastroUsuario = lazy(() => import('./pages/CadastroUsuario'))
const MinhaConta = lazy(() => import('./pages/MinhaConta'))
const CadastroAnuncio = lazy(() => import('./pages/CadastroAnuncio'))
const MinhasVendas = lazy(() => import('./pages/MinhasVendas'))
const AvisosLegais = lazy(() => import('./pages/AvisosLegais'))
const Vendedor = lazy(() => import('./pages/Vendedor'))
const MeusFavoritos = lazy(() => import('./pages/MeusFavoritos'))

const Routes: React.FC = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  let history = useHistory()

  return (
    <BrowserRouter>
      <Suspense fallback={<>  </>}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/produtos" component={Produto} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={CadastroUsuario} />
          <Route path="/search" component={Search} />
          {
            dataCategoria.map(data => {
              if (data.componentes.length >= 1) {
                return data.componentes.map(data => {
                  return <Route path={`/${data.route}`} render={() => <Categoria titulo={data.titulo} history={history} descricao={data.descricao} />} />
                })
              } else return null
            })
          }
          {
            dataCategoria.map(data => {
              return <Route path={`/${data.route}`} render={() => <Categoria titulo={data.titulo} history={history} descricao={data.descricao} />} />
            })
          }
          <Route path="/v" component={Vendedor} />
          <Route path="/conta/minha-conta" component={MinhaConta} />
          <Route path="/conta/minhas-vendas" component={MinhasVendas} />
          <Route path="/conta/meus-favoritos" component={MeusFavoritos} />
          <Route path="/cadastro/produto" component={CadastroAnuncio} />
          <Route path="/avisos-legais" component={AvisosLegais} />
          <Route path="*" render={() => <Redirect to='/' />} />
        </Switch>
      </Suspense>
    </BrowserRouter >
  )
}

export default Routes
